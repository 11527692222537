import React, { useState } from 'react'
import { useLocation, useSearchParams, Navigate } from 'react-router-dom'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useSelector, shallowEqual } from 'react-redux'
import loadable from '@loadable/component'
import { useAuthContext } from '../../../../../contexts/AuthProvider'
import { useRoleContext } from '../../../../../contexts/RoleProvider'
import FancyLoader from '../../../../atoms/FancyLoader'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'
const MediaUpload = loadable(() => import(/* webpackPrefetch: true */ './MediaUpload'), { ssr: false })

const UploadThankYou = () => {
	const { event } = useEventContext()
	const user = useAuthContext()
	const { role } = useRoleContext()

	const location = useLocation()
	const [queryParams] = useSearchParams()
	const privateKey = queryParams.get('v')

	/** Set uploader state */
	const [isComplete, setIsComplete] = useState(false)

	/** Create handler to close the Uppy after a successful upload */
	const handleClose = () => setIsComplete(true)

	/** Retrieve uploads of the Uppy widget */
	const uploads = useSelector((s) => s.uploader.uploads, shallowEqual)

	/** Determine if the user is authenticated
	 * or if we have info from the temp user */
	const account = useTemporaryUserContext()

	if (event && role != 'public') {
		/**
		 * Return our Media Upload Form
		 * if we haven't completed an upload yet.
		 */
		if (!isComplete && event.status === 'published') {
			return <MediaUpload event={event} handleClose={handleClose} />
		} else if (isComplete && uploads.length > 0) {
			/**
			 * If the upload has been completed
			 * and some uploads are available from the widget.
			 */
			/**
			 * Check that the user is authenticated
			 * (or has a temporary account id) */
			if (account) {
				uploads.forEach((file) => {
					var newFile = { ...file }
					newFile.contributorId = user.id
					newFile.eventId = event.id
					newFile.socketReloadPage = true
					newFile.isThankYouVideo = true
					newFile.privateKey = privateKey || undefined
				})

				return (
					<Navigate
						to={`/e/${event.uuid}${location.search}`}
						state={{
							fromUpload: true,
							uploads,
						}}
					/>
				)
			} else {
				/**
				 * Otherwise, we need to register the Recipient.
				 */

				return (
					<Navigate
						to={`/e/${event.uuid}/upload-thankyou/recipient${location.search}`}
						state={{
							fromUpload: true,
							uploads,
						}}
					/>
				)
			}
		} else if (event.status !== 'published') {
			/**
			 * If the status is different that published, redirect to event page
			 */
			console.log('not marked as published...')
			return <Navigate to={`/e/${event.uuid}${location.search}`} />
		}
	}
	return <FancyLoader />
}

export default UploadThankYou
